<template>
  <router-view />
</template>

<script>
export default {
  name: "Locale",
  created() {
    this.changeLanguage();
  },
  methods: {
    changeLanguage() {
      this.$translation.setLocale(this.$route.params.locale);
    },
  },
  watch: {
    $route() {
      this.changeLanguage();
    },
  },
};
</script>
